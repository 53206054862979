<template>
  <div>
    <div class="nav-title">{{ $t("tradetypeManage") }}</div>

    <div class="setting-nav-container">
      <el-menu :default-active="activeMenu" router class="setting-sider">
        <el-menu-item index="/tradetype/tradeType">
          <template slot="title">
            {{ $t("tradeType") }}
          </template>
        </el-menu-item>
        <el-menu-item index="/tradetype/paymentClass">
          <template slot="title">
            {{ $t("paymentClass") }}
          </template>
        </el-menu-item>

        <el-menu-item index="/tradetype/paymentLogos">
          <template slot="title">
            {{ $t("paymentLogos.title") }}
          </template>
        </el-menu-item>

        <el-menu-item index="/tradetype/paymentType">
          <template slot="title"> {{ $t("paymentType") }} </template>
        </el-menu-item>

        <el-menu-item index="/tradetype/tableTent">
          <template slot="title"> {{ $t("tableTentManage") }} </template>
        </el-menu-item>
      </el-menu>

      <div class="setting-view">
        <div class="view-container">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeMenu() {
      return this.$route.path;
    }
  }
};
</script>
<style scoped>
.setting-nav-container {
  display: block;
}

.setting-sider {
  float: left;
  width: 260px;
}

.setting-view {
  margin-left: 280px;
  padding-left: 0;
  display: block;
}
</style>
